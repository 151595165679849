import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  titleAnalyst: {
    fontSize: '1.1em',
    fontWeight: 600,
    marginBottom: 8,
  },
  subTitleAnalyst: {
    margin: 0,
  },
  container: {
    padding: '0px 80px 0px 160px',
    '@media (max-width:600px)': {
      padding: '20px 0px 20px 0px',
    },
  },
}));

export default () => {
  const styles = useStyles();

  return (
    <Grid container className={styles.container}>
      <h3 style={{ marginBottom: 40, marginTop: 100 }}>IR Contacts</h3>
      <Grid container>
        <Grid item>
          <p className={styles.titleAnalyst}>Tina Tønnessen</p>
          <p className={styles.subTitleAnalyst}>
            Chief Financial Officer (CFO)
          </p>
          <p className={styles.subTitleAnalyst}>
            Mobile:
            <a href="tel:+4792888728" style={{ paddingLeft: '6px' }}>
              +47 40 63 95 56
            </a>
          </p>
          <p className={styles.subTitleAnalyst}>
            E-mail:
            <a
              href="mailto:tina.tonnessen@vowasa.com"
              style={{ paddingLeft: '6px' }}
            >
              tina.tonnessen@vowasa.com
            </a>
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};
