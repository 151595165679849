import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import { moreYears, moreLinks } from '../../mockData/investors';
import { COLOR_CORAL, COLOR_RED_VISITED } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  textYear: {
    margin: '4px 0 12px',
    fontSize: 18,
    lineHeight: '28px',
    color: '#282828',
  },
  textQuarter: {
    margin: '0 0 6px',
    fontSize: 18,
    lineHeight: '28px',
    color: '#282828',
  },
  link: {
    margin: '0 0 8px',
    display: 'block',
    color: COLOR_CORAL,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: COLOR_CORAL,
    },
    '&:visited': {
      color: COLOR_RED_VISITED,
    },
  },
  separatorLine: {
    borderColor: '#282828',
  },
  containerText: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
  },
  textMoreLink: {
    fontSize: 16,
    margin: 0,
    color: COLOR_CORAL,
  },
  textThumbnail: {
    margin: 0,
    fontSize: 16,
    lineHeight: '28px',
    color: '#282828',
  },
  textDate: {
    margin: 0,
    fontSize: 18,
    lineHeight: '28px',
    color: '#282828',
  },
  containerNumbers: {
    marginBottom: 32,
  },
  expansion: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none',
    '&:before': {
      display: 'none',
    },
  },
  contentSummary: {
    margin: '0 !important',
    flexGrow: '0',
  },
  rootSummary: {
    minHeight: '30px !important',
  },
  moreExpansion: {
    margin: 0,
    padding: '2px 4px',
    color: COLOR_CORAL,
  },
  rightAligned: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  expansionDetails: {
    padding: '0px',
  },
  moreButton: {
    paddingLeft: '3px',
    maxWidth: '140px',
    '&:hover': {
      backgroundColor: 'rgba(220,220,220,0)',
    },
  },
  arrowDown: {
    color: COLOR_CORAL,
  },
  container: {
    padding: '0px 80px 0px 160px',
    maxWidth: 1200,
    marginTop: 80,
    marginBottom: 40,
    '@media (max-width:600px)': {
      padding: '0px 0px 20px 0px',
      marginTop: 40,
    },
  },
  agmContainer: {
    marginBottom: 40,
  },
  annualGeneralMeetingsGroup: {
    padding: '10px 0',
  },
}));
const groupByQuarters = array => {
  const result = {};
  array.map(({ node: { yearQuarter, ...rest } }) => {
    result[yearQuarter] =
      result[yearQuarter] && result[yearQuarter].length
        ? [...result[yearQuarter], { ...rest }]
        : [{ ...rest }];
    return null;
  });
  return result;
};

export default () => {
  const styles = useStyles();
  const data = useStaticQuery(query);
  const annualGeneralMeetings = data.allContentfulAnnualGeneralMeetings.group.sort(
    (a, b) => parseInt(b.year) - parseInt(a.year)
  );
  let quarterReports = data.allContentfulQuarterlyReports.group.sort(
    (a, b) => parseInt(b.year) - parseInt(a.year)
  );
  quarterReports = quarterReports.map(({ year, edges }) => ({
    year,
    quarters: Object.entries(groupByQuarters(edges)).map(entry => ({
      quarter: entry[0],
      documents: entry[1],
    })),
  }));

  return (
    <Grid container direction="column" className={styles.container} spacing={2}>
      <Grid item>
        <h3 style={{ marginBottom: 40 }}>Reports</h3>

        {/* Extra non cms document field */}
        {/* <p className={styles.textYear}>Annual General Meeting 2021</p>
        <Grid container className={styles.agmContainer}>
          <Grid item xs={12} md={4}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2021%2FFisjonsplan-Demerger%20plan_AGM140521.pdf?alt=media&token=0365e59b-1c18-43b8-8401-31c617089d54"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Fisjonsplan / Demerger plan
            </a>
          </Grid>
          <Grid item xs={12} md={4}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2021%2FNominationCommitteeProposal2021.pdf?alt=media&token=fea5c49b-651a-4f21-934d-8f2cf18573a4"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Nomination Committee’s proposal NOR
            </a>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2021%2FNominationCommitteeProposal2021EnglishTranslation.pdf?alt=media&token=eab3d556-5804-464a-ba5b-3cb0f0007bd0"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Nomination Committee’s proposal ENG
            </a>
          </Grid>
          <Grid item xs={12} md={4}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2021%2FVow%20ASA%20-%20Notice%20of%20AGM%202021.pdf?alt=media&token=a5a4e54e-1791-4234-b27b-210f17c82c76"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Notice of AGM 2021
            </a>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2021%2FVow_ASA_-_Minutes_AGM_140521.pdf?alt=media&token=70d10b2c-6871-42fe-a06b-656d54b2b2cb"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Vow ASA - Minutes AGM 140521
            </a>
          </Grid>
        </Grid>
        <p className={styles.textYear}>
          Extraordinary General Meeting 8 December 2020
        </p>
        <Grid container className={styles.agmContainer}>
          <Grid item xs={12} md={4}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/Vow%20ASA%20-%20Notice%20of%20EGM%20081220.pdf?alt=media&token=bddce403-7ded-48a9-9da9-1e27b3076812"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Notice of EGM 2020
            </a>
          </Grid>
          <Grid item xs={12} md={4}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/Innstilling%20fra%20Valgkomiteen%20i%20Vow%20ASA.pdf?alt=media&token=fa07e379-6e5b-4436-8e61-118e9a41693b"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Nomination Committee’s proposal
            </a>
          </Grid>
          <Grid item xs={12} md={4}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2020%2FVow%20ASA%20-%20Minutes%20EGM%20081220.pdf?alt=media&token=08f0abd8-7a6b-4d0c-9694-4649adf4a68f"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Vow ASA - Minutes EGM 081220
            </a>
          </Grid>
        </Grid>

        <p className={styles.textYear}>Annual General Meeting 2020</p>

        <Grid container className={styles.agmContainer}>
          {annualGeneralMeetings.map(({ edges }) => (
            <Grid container className={styles.annualGeneralMeetingsGroup}>
              {edges.map(
                ({
                  node: {
                    documentLabel,
                    document: {
                      file: { url },
                    },
                  },
                }) => (
                  <Grid item xs={12} md={4} key={documentLabel}>
                    <a
                      href={`https:${url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.link}
                    >
                      {documentLabel}
                    </a>
                  </Grid>
                )
              )}
            </Grid>
          ))}
        </Grid> */}
        {/* END OF Extra non cms document field */}

        <Grid container>
          <Grid item sm>
            {quarterReports.map(({ year, quarters }) => (
              <div className={styles.containerNumbers} key={year}>
                <hr className={styles.separatorLine} />
                <p className={styles.textYear}>{year}</p>
                <Grid container spacing={4}>
                  {quarters.map(({ quarter, documents }) => (
                    <Grid item key={quarter}>
                      <p className={styles.textQuarter}>{quarter}</p>
                      {documents.map(
                        ({
                          documentLabel,
                          document: {
                            file: { url },
                          },
                          extraFileSlug,
                        }) => {
                          if (extraFileSlug) {
                            return (
                              <a
                                href={extraFileSlug}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.link}
                                key={url}
                              >
                                {documentLabel}
                              </a>
                            );
                          }
                          return (
                            <a
                              href={`https:${url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={styles.link}
                              key={url}
                            >
                              {documentLabel}
                            </a>
                          );
                        }
                      )}
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))}
          </Grid>
        </Grid>
        <ExpansionPanel
          classes={{
            root: styles.expansion,
          }}
        >
          <Container className={styles.rightAligned}>
            <ExpansionPanelSummary
              classes={{
                content: styles.contentSummary,
                root: styles.rootSummary,
              }}
              className={styles.moreButton}
              expandIcon={<ExpandMoreIcon className={styles.arrowDown} />}
            >
              <p className={styles.moreExpansion}>See All</p>
            </ExpansionPanelSummary>
          </Container>
          <ExpansionPanelDetails className={styles.expansionDetails}>
            <Grid
              container
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Grid item sm>
                {Object.keys(moreYears)
                  .reverse()
                  .map(year => (
                    <div className={styles.containerNumbers} key={year}>
                      <hr className={styles.separatorLine} />
                      <p className={styles.textYear}>{year}</p>
                      <Grid container spacing={4}>
                        {Object.keys(moreYears[year]).map(quarter => (
                          <Grid item key={quarter}>
                            <p className={styles.textQuarter}>{quarter}</p>
                            {moreYears[year][quarter].map((item, key) => (
                              <a
                                href={moreLinks[year][quarter][key]}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ display: 'block', color: COLOR_CORAL }}
                                className={styles.link}
                                key={item}
                              >
                                {item}
                              </a>
                            ))}
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  ))}
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    </Grid>
  );
};

const query = graphql`
  query AnnualReports {
    allContentfulQuarterlyReports(sort: { fields: yearQuarter, order: ASC }) {
      group(field: year) {
        year: fieldValue
        edges {
          node {
            yearQuarter
            documentLabel
            extraFileSlug
            document {
              file {
                url
              }
            }
          }
        }
      }
    }
    allContentfulAnnualGeneralMeetings(
      sort: { fields: createdAt, order: DESC }
    ) {
      group(field: year) {
        year: fieldValue
        edges {
          node {
            documentLabel
            document {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;
