export default [
  {
    name: 'Extraordinary general meeting 2020',
    date: '09 Jan 2020',
    anchor: 'EGM notice 20.01.09',
    link:
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/EGM%20notice%2020.01.09.pdf?alt=media&token=cbb2b091-4fe7-4f54-8b02-79a57306c19b',
  },
  {
    name: 'Extraordinary general meeting 2019',
    date: '02 Oct 2019',
    anchor: 'EGM minutes 19.10.02, EGM notice 19.10.02',
    link:
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/EGM%20minutes%2019.10.02.pdf?alt=media&token=e3cd245b-39b0-4cc1-b997-362fc44b969b',
  },
  {
    name: 'Annual general meeting 2019',
    date: '23 May 2019',
    anchor: 'AGM minutes 19.05.23, AGM notice 19.05.23',
    link:
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/AGM%20notice%2019.05.23.pdf?alt=media&token=41e9fb78-8672-44b8-bc09-66195cb8357c',
  },
  {
    name: 'Annual general meeting 2018',
    date: '23 May 2018',
    anchor: 'AGM minutes 18.05.23, AGM notice 18.05.23',
    link:
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/AGM%20notice%2018.05.23.pdf?alt=media&token=d847d32c-1208-4586-9a09-43b056d1db47',
  },
  {
    name: 'Extraordinary general meeting 2017',
    date: '24 Nov 2017',
    anchor: 'EGM notice 17.11.24',
    link:
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/EGM%20notice%2017.11.24.pdf?alt=media&token=2c14ce41-2ef8-4e09-9428-2a9d918c288c',
  },
  {
    name: 'Annual general meeting 2017',
    date: '16 May 2017',
    anchor: 'AGM minutes 17.05.16, AGM notice 17.05.16',
    link:
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/AGM%20notice%2017.05.16.pdf?alt=media&token=2c699938-71f4-4ca3-b412-f580714660e5',
  },
  {
    name: 'Annual general meeting 2016',
    date: '12 May 2016',
    anchor:
      'AGM minutes 16.05.12, AGM minutes 16.05.12 (2), AGM notice 16.05.12',
    link:
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/AGM%20notice%2016.05.12.pdf?alt=media&token=4de26221-4bf9-48dd-adf2-f41155805680',
  },
  {
    name: 'Annual general meeting 2015',
    date: '12 May 2015',
    anchor:
      'AGM minutes 15.05.12, AGM minutes 15.05.12 (2), AGM notice 15.05.12',
    link:
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/AGM%20notice%2015.05.12.pdf?alt=media&token=9526e7ab-8d2a-4fce-ae23-4ad6757d5c43',
  },
];
