import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import Button from '../button';
import { COLOR_CORAL, COLOR_RED_VISITED } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  textYear: {
    margin: '4px 0 12px',
    fontSize: 18,
    lineHeight: '28px',
    color: '#282828',
  },
  textQuarter: {
    margin: '0 0 6px',
    fontSize: 18,
    lineHeight: '28px',
    color: '#282828',
  },
  textContent: {
    color: '#1866C1',
    fontSize: 16,
    margin: '0 0 8px',
  },
  separatorLine: {
    borderColor: '#282828',
  },
  containerText: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
  },
  textMoreLink: {
    fontSize: 16,
    margin: 0,
    color: COLOR_CORAL,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: COLOR_CORAL,
    },
    '&:visited': {
      color: COLOR_RED_VISITED,
    },
  },
  textThumbnail: {
    margin: 0,
    fontSize: 16,
    lineHeight: '28px',
    color: '#282828',
  },
  textDate: {
    margin: 0,
    fontSize: 18,
    lineHeight: '28px',
    color: '#282828',
  },
  containerNumbers: {
    marginBottom: 32,
  },
  expansion: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none',
    '&:before': {
      display: 'none',
    },
  },
  contentSummary: {
    margin: '0 !important',
    flexGrow: '0',
  },
  rootSummary: {
    minHeight: '30px !important',
    margin: 'auto',
  },
  moreExpansion: {
    margin: 0,
    padding: '2px 4px',
  },
  rightAligned: {
    marginLeft: -20,
    marginTop: -10,
  },
  expansionDetails: {
    padding: '0px',
  },
  moreButton: {
    paddingLeft: '3px',
    maxWidth: '140px',
    '&:hover': {
      backgroundColor: 'rgba(220,220,220,0.6)',
    },
  },
  arrowDown: {
    color: COLOR_CORAL,
  },
  container: {
    padding: '0px 80px 0px 160px',
    marginTop: 80,
    marginBottom: 40,
    '@media (max-width:600px)': {
      padding: '0px 0px 20px 0px',
      marginTop: 40,
    },
  },
}));

export default ({ news }) => {
  const styles = useStyles();

  const InfoText = ({ date, title, slug }) => (
    <div className={styles.containerText}>
      <p className={styles.textDate}>{date}</p>
      <Grid container alignItems="center" spacing={3}>
        <Grid item>
          <p className={styles.textThumbnail}>{title}</p>
          <a href={`/blog/${slug}`} className={styles.textMoreLink}>
            More
          </a>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Grid container direction="column" className={styles.container}>
      <Grid item>
        <h3 style={{ marginBottom: 40 }}>News</h3>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item sm={6}>
            {news.map(({ node }) => (
              <InfoText
                date={node.publishDate}
                title={node.title}
                slug={node.slug}
              />
            ))}
          </Grid>
          <Grid item>
            <Button component={Link} label="More news" to="/news" />
          </Grid>
        </Grid>
      </Grid>
      <h3 style={{ marginBottom: 40, marginTop: 60 }}>
        Stock Exchanges Notices
      </h3>
      {/* <iframe
        title="vow"
        style={{ width: '100%', height: '300px', marginLeft: -10 }}
        src="https://ir.asp.manamind.com/products/html/companyDisclosuresArchive.do?key=scanship_irn&amp;lang=en&amp;fragment=false&amp;externalCss=https://www.scanship.no/sites/all/themes/pixture_reloaded/css/pixture_reloaded.css"
        width="300"
        height="150"
        frameBorder="0"
      /> */}
      <iframe
        title="vow"
        style={{ width: '100%', height: '500px', marginLeft: 0 }}
        src="https://ir.oms.no/component/companyDisclosuresArchive?token=scanship_irn&lang=en"
        width="300"
        height="150"
        frameBorder="0"
      />
      <br />
    </Grid>
  );
};
