import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles/index';
import arrowWhite from '../images/arrowWhite.png';
import { COLOR_CORAL } from '../utils/constants';

const useStyles = makeStyles({
  firstStyle: {
    marginTop: '40px',
    borderRadius: '0px',
    boxShadow: 'none',
    fontSize: '12px',
    textTransform: 'none',
    fontFamily: 'Arial',
    color: 'white',
    padding: '8px 8px 8px 12px',
    backgroundColor: COLOR_CORAL,
    '&:hover': {
      backgroundColor: COLOR_CORAL,
      boxShadow: 'none',
    },
  },
  secondStyle: {
    color: props => props.color,
    backgroundColor: props => props.backgroundColor,
    marginLeft: props => props.marginLeft,
    marginBottom: props => props.marginBottom,
    '@media (min-width:400px) and (max-width:750px)': {
      marginLeft: props => props.padMarginLeft,
    },
  },
});

const DefaultButton = ({
  label,
  component,
  buttonContainer,
  to,
  href,
  btnStyle,
  ...props
}) => {
  const { firstStyle, secondStyle } = useStyles(props);
  return (
    <Button
      variant="contained"
      className={`${firstStyle} ${secondStyle}`}
      style={buttonContainer}
      component={component}
      to={to}
      href={href}
      target="_blank"
    >
      {label}
      <img src={arrowWhite} style={{ width: 10, paddingLeft: 10 }} />
    </Button>
  );
};

export default DefaultButton;
