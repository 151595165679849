import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';

const useStyles = makeStyles(() => ({
  container: {
    padding: '0px 60px 0px 160px',
    maxWidth: 1200,
    marginTop: 80,
    marginBottom: 80,
    '@media (max-width:600px)': {
      padding: '0px 0px 20px 0px',
      marginTop: 40,
    },
  },
  textDate: {
    margin: '4px 0 12px',
    fontSize: 14,
    lineHeight: '28px',
    color: '#282828',
  },
  textYear: {
    margin: '4px 0 12px',
    fontSize: 18,
    lineHeight: '28px',
    color: '#282828',
  },
  separatorLine: {
    borderColor: '#282828',
  },
}));

export default () => {
  const classes = useStyles();
  const data = useStaticQuery(query);
  return (
    <Grid container className={classes.container}>
      <h3 style={{ marginBottom: 40 }}>Financial Calendar</h3>
      {/* <iframe
        title="vow"
        style={{ width: '100%', height: '100px', marginLeft: -10 }}
        src="https://ir.asp.manamind.com/products/html/financialCalendar.do?key=scanship_irn&amp;lang=en&amp;fragment=false&amp;externalCss=https://www.scanship.no/sites/all/themes/pixture_reloaded/css/pixture_reloaded.css"
        width="300"
        height="150"
        frameBorder="0"
      /> */}
      <Grid item xs={12}>
        <p className={classes.textYear}>Date</p>
        <hr className={classes.separatorLine} />
        {data.allContentfulFinancialCalendarEntry.edges.map(
          ({ node: { label, date } }) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                maxWidth: 400,
              }}
            >
              <p className={classes.textDate}>{date}</p>
              <p className={classes.textDate}>{label}</p>
            </div>
          )
        )}
      </Grid>
      <Grid item xs={12} />
    </Grid>
  );
};

const query = graphql`
  query FinancialCalendar {
    allContentfulFinancialCalendarEntry {
      edges {
        node {
          label
          date(formatString: "DD.MM.YYYY")
        }
      }
    }
  }
`;
