/* export const years = {
  '2018': {
    // Q1: ['Operational update', 'Presentation update', ''],
    Q2: ['First Half Year Presentation', 'First Half Year Report', ''],
    Q3: ['Operational update', 'Presentation update', ''],
    Q4: [
      'Second Half Year Presentation',
      'Second Half Year Report',
      'Annual-Report',
    ],
  },
  '2019': {
    Q1: ['Operational update ', 'Presentation update ', ''],
    Q2: ['First Half Year Report', 'First Half Year Presentation', ''],
    Q3: ['Operational update', 'Presentation update', ''],
    Q4: [
      'Second Half Year Report',
      'Second Half Year Presentation',
      'Annual Report 2019 (for web)',
      'Annual Report 2019 (for print)',
    ],
  },
  '2020': {
    Q1: ['Operational update ', 'Presentation update ', ''],
    Q2: [
      'First Half Year Report',
      'First Half Year Presentation',
      'First Half Year Q&A',
    ],
    Q3: ['Operational update', 'Presentation update', 'Trading update Q&A'],
  },
}; */

export const moreYears = {
  '2017': {
    // Q1: ['Operational update', 'Presentation update', ''],
    Q2: ['First Half Year Presentation', 'First Half Year Report'],
    // Q3: ['Operational update', 'Presentation update', ''],
    Q4: ['Second Half Year', 'Second Half Year report', 'Annual-Report'],
  },
  '2016': {
    Q1: ['Quarterly report'],
    Q2: ['Quarterly report', 'Quarterly presentation'],
    Q3: ['Quarterly report'],
    Q4: ['Annual report', 'Quarterly report', 'Quarterly presentation'],
  },
  '2015': {
    Q1: ['Quarterly report'],
    Q2: ['Quarterly report', 'Quarterly presentation'],
    Q3: ['Quarterly report'],
    Q4: ['Annual report', 'Quarterly report', 'Quarterly presentation'],
  },
  '2014': {
    Q1: ['Quarterly report', 'Quarterly presentation'],
    Q2: ['Quarterly report', 'Quarterly presentation'],
    Q3: ['Quarterly report', 'Quarterly presentation'],
    Q4: ['Annual report', 'Quarterly report', 'Quarterly presentation'],
  },
  '2013': {
    // Q1: ['Quarterly report', 'Quarterly presentation', ''],
    // Q2: ['Quarterly report', 'Quarterly presentation', ''],
    // Q3: ['Quarterly report', 'Quarterly presentation', ''],
    Q4: ['Annual report'],
  },
};

/* export const links = {
  '2018': {
    Q1: ['', '', ''],
    Q2: [
      'http://www.kooldigital.se/wp-content/uploads/2020/10/SSHIP-First-Half-Year-2018-Presentation.pdf',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/SSHIP-First-Half-Year-2018-Report.pdf',
      '',
    ],
    Q3: [
      'http://www.kooldigital.se/wp-content/uploads/2020/10/2018-Operational-update-3Q.pdf',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/2018-Presentation-Operational-update-3Q.pdf',
      '',
    ],
    Q4: [
      'http://www.kooldigital.se/wp-content/uploads/2020/10/SSHIP-Second-Half-Year-2018-Presentation.pdf',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/SSHIP-Second-Half-Year-2018-Report.pdf',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/SSHIP-Annual-Report-2018.pdf',
    ],
  },
  '2019': {
    Q1: [
      'http://www.kooldigital.se/wp-content/uploads/2020/10/SSHIP-Presentation-Operational-update-1Q-2019.pdf',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/SSHIP-Operational-update-1Q-2019.pdf',
      '',
    ],
    Q2: [
      'http://www.kooldigital.se/wp-content/uploads/2020/10/SSHIP-First-Half-Year-2019-Report.pdf',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/SSHIP-First-Half-Year-2019-Presentation.pdf',
      '',
    ],
    Q3: [
      'http://www.kooldigital.se/wp-content/uploads/2020/10/SSHIP-Operational-update-3Q-2019.pdf',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/SSHIP-Presentation-Operational-update-3Q-2019.pdf',
      '',
    ],
    Q4: [
      // 'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/VOW%20-%20Second%20Half%20Year%202019%20Report.pdf?alt=media&token=2e8b7408-8e3e-4f2d-949e-bbaef2f91433',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/VOW-Second-Half-Year-2019-Report.pdf',
      // 'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/200226%20Vow%20ASA%20-%20Second%20Half%202019%20Presentation.pdf?alt=media&token=058f56c2-c7e6-4d85-94f7-e43c2380f55f',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/200226-Vow-ASA-Second-Half-2019-Presentation.pdf',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/Vow-annual-2019-WEB.pdf',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/Vow-annual-2019-PRINT.pdf',
    ],
  },
  '2020': {
    Q1: [
      'http://www.kooldigital.se/wp-content/uploads/2020/10/vow-operational-update-1q-2020.pdf',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/vow-presentation-operational-update-1q-2020.pdf',
    ],
    Q2: [
      'http://www.kooldigital.se/wp-content/uploads/2020/10/VOW-First-Half-Year-2020-Report.pdf',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/VOW-First-Half-Year-2020-Presentation.pdf',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/Trading-update-QA-2.pdf',
    ],
    Q3: [
      'http://www.kooldigital.se/wp-content/uploads/2020/10/Vow-Operational-update-Q3-2020.pdf',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/Vow-Presentation-Operational-update-Q3-2020.pdf',
      'http://www.kooldigital.se/wp-content/uploads/2020/10/Trading-update-QA.pdf',
    ],
    Q4: ['', '', ''],
  },
}; */

export const moreLinks = {
  '2013': {
    Q4: [
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2013%2Fscanship_holding_as_-_annual_report_2013.pdf?alt=media&token=ab687f82-7458-4c6f-820e-e35153a3c4a0',
    ],
  },
  '2014': {
    Q1: [
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2014%2Fscanship_holding_asa_consolidated_report_q1_2014.pdf?alt=media&token=592fe0a4-7b90-4c0d-88da-8d9e6e9b2dad',
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2014%2Fscanship_holding_asa_presentation_of_q1_2014.pdf?alt=media&token=06d19d78-1f4e-498a-a60c-571a082592e4',
    ],
    Q2: [
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2014%2Fscanship_holding_asa_consolidated_report_q2_2014.pdf?alt=media&token=cf363e32-022b-4d50-958a-b17f36923f5f',
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2014%2Fscanship_holding_asa_presentation_of_q2_2014.pdf?alt=media&token=7f6bdf56-031a-4582-8d07-c60c610d19ee',
    ],
    Q3: [
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2014%2Fsship_-_quarterly_report_q3_2014.pdf?alt=media&token=bfb4a208-aac0-4fc4-a4ce-8c42dcba89c7',
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2014%2Fsship_-_presentation_q3_2014.pdf?alt=media&token=b7127b2a-beb7-40d8-9090-cff0da17067d',
    ],
    Q4: [
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2014%2Fsship_-_annual_report_2014.pdf?alt=media&token=83f27b6a-3780-4a51-9601-482ac8fa3f6b',
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2014%2Fsship_-_quarterly_report_q4_2014.pdf?alt=media&token=d85870aa-4847-4dc9-9c7a-ade6bbb9228c',
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2014%2Fsship_-_presentation_q4_2014.pdf?alt=media&token=9a9a94f3-6039-4f9e-8d62-6c543ef348ae',
    ],
  },
  '2015': {
    Q1: [
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2015%2Fsship_1q_2015.pdf?alt=media&token=498d31e8-685d-4863-aadd-0133bb2e9302',
    ],
    Q2: [
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2015%2Fsship_quarterly_report_2015_2q.pdf?alt=media&token=24002462-2f15-4241-920e-85870ad93fbf',
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2015%2Fsship_presentation_2015_2q.pdf?alt=media&token=d89cc401-0be1-4fca-8375-d078b0b3b7be',
    ],
    Q3: [
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2015%2Fsship_quarterly_report_2015_3q.pdf?alt=media&token=b339317d-5b98-4519-a263-7e1f4bb04f60',
    ],
    Q4: [
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2015%2Fsship_-_annual_report_2015.pdf?alt=media&token=4460b42c-ad50-4559-87f1-cd8deee96810',
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2015%2Fsship_quarterly_report_2015_4q.pdf?alt=media&token=14a1aa4d-2f2e-442a-adee-0e0ffedde483',
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2015%2Fsship_presentation_4q15.pdf?alt=media&token=be502c85-032b-4cb4-b66a-b75b8278731a',
    ],
  },
  '2016': {
    Q1: [
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2016%2Fsship_quarterly_report_2016_1q.pdf?alt=media&token=1457cabb-0ee0-4595-9d1e-97ea35811e3b',
    ],
    Q2: [
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2016%2Fsship_quarterly_report_2016-2q.pdf?alt=media&token=fb4bced3-2c96-4ced-8c5b-a12c2dd1f51f',
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2016%2Fsship_presentation_2016-2q.pdf?alt=media&token=eb6d9c99-31ab-4623-9992-91d10001d710',
    ],
    Q3: [
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2016%2Fsship_quarterly_report_2016_3q.pdf?alt=media&token=75fd709b-106e-47f4-95a9-b17cb0a8c611',
    ],
    Q4: [
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2016%2Fannualreport2016.pdf?alt=media&token=81155652-3bb0-4f79-a6ff-9a8824afcf5c',
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2016%2FSSHIP-4Q2016.pdf?alt=media&token=f78fd5c7-54fe-46ad-a1b1-8e50cd6e1d1f',
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2016%2FSSHIP-Presentation-4Q16-01-03-2017.pdf?alt=media&token=730872db-a7a5-46c6-8b4c-ab5e6ee25dac',
    ],
  },
  '2017': {
    Q2: [
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2017%2FSSHIP-First-Half-Year-2017-presentation.pdf?alt=media&token=ad2d6abe-2f74-4728-93c9-854d2746a1ca',
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2017%2FSSHIP-First-Half-Year-2017-report.pdf?alt=media&token=b9195988-2d14-4f1a-be92-add896640776',
    ],
    Q4: [
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2017%2FSSHIP-Second-Half-Year-2017-Presentation.pdf?alt=media&token=89fe4449-4aec-434b-8360-b8dd60c4f2a7',
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2017%2FSSHIP-Second-Half-Year-2017-report.pdf?alt=media&token=5bbc4b3b-e7e9-40ad-b1a4-92eab238b258',
      'https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2017%2FSSHIP-Annual-Report-2017-Final.pdf?alt=media&token=8252e2e3-9d23-461a-8bca-27c66a9b118d',
    ],
  },
};
