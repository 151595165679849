import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles, useTheme } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import H1 from '../../components/header1';
import {
  FirstTab,
  SecondTab,
  ThirdTab,
  FourthTab,
  FifthTab,
  SixthTab,
} from '../../components/investorTabs';
import { TabMenu, TabPanel } from '../../components/Tabs';
import { SUB_PAGES } from '../../utils/constants';
import { isBrowser } from '../../utils/functions';

const { titles, investorTabs } = SUB_PAGES.INVESTORS;

const FIRST_TAB_VALUE = investorTabs[0].tabValue;
const SECOND_TAB_VALUE = investorTabs[1].tabValue;
const THIRD_TAB_VALUE = investorTabs[2].tabValue;
const FOURTH_TAB_VALUE = investorTabs[3].tabValue;
const FIFTH_TAB_VALUE = investorTabs[4].tabValue;
const SIXTH_TAB_VALUE = investorTabs[5].tabValue;

export const pageQuery = graphql`
  query InvestorNews {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 4
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MM.DD.YYYY")
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'default',
    paddingLeft: 0,
    paddingRight: 0,
  },
  mobileStyle: {
    '@media (max-width:600px)': {
      padding: '0px 20px 0px 20px',
    },
  },
  fifthTabPanelStyle: {
    margin: '80px 0',
  },
}));

const getIndexFromPathName = pathname => {
  const index = investorTabs.findIndex(element => element.tabLink === pathname);
  return index !== -1 ? index : 0;
};

const InvestorPage = () => {
  const styles = useStyles();
  const theme = useTheme();
  const location = isBrowser() && window.location.pathname;
  const [selectedTab, setSelectedTab] = useState(
    getIndexFromPathName(location)
  );

  const handleChange = (_, index) => {
    setSelectedTab(index);
    const urlToPush = investorTabs[index].tabLink;
    if (isBrowser()) {
      window.history.pushState(null, null, urlToPush);
    }
  };

  const handleChangeIndex = index => {
    setSelectedTab(index);
  };

  useEffect(() => {
    if (isBrowser) {
      setSelectedTab(getIndexFromPathName(window.location.pathname));
    }
  });

  const news = useStaticQuery(pageQuery).allContentfulBlogPost.edges;

  return (
    <Container maxWidth="xl" className={styles.root}>
      <Grid container direction="column">
        <Grid item>
          <Container maxWidth="xl">
            <H1 title="INVESTORS" />
          </Container>
        </Grid>
        <TabMenu value={selectedTab} onChange={handleChange} tabs={titles} />
        <div className={styles.mobileStyle}>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={selectedTab}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={FIRST_TAB_VALUE} currentValue={selectedTab}>
              <FirstTab />
            </TabPanel>
            <TabPanel value={SECOND_TAB_VALUE} currentValue={selectedTab}>
              <SecondTab news={news} />
            </TabPanel>
            <TabPanel value={THIRD_TAB_VALUE} currentValue={selectedTab}>
              <ThirdTab news={news} />
            </TabPanel>
            <TabPanel value={FOURTH_TAB_VALUE} currentValue={selectedTab}>
              <FourthTab />
            </TabPanel>
            <TabPanel
              value={FIFTH_TAB_VALUE}
              currentValue={selectedTab}
              className={styles.fifthTabPanelStyle}
            >
              <FifthTab />
            </TabPanel>
            <TabPanel
              value={SIXTH_TAB_VALUE}
              currentValue={selectedTab}
              className={styles.fifthTabPanelStyle}
            >
              <SixthTab />
            </TabPanel>
          </SwipeableViews>
        </div>
      </Grid>
    </Container>
  );
};

InvestorPage.propTypes = {};

export default InvestorPage;
