import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { COLOR_CORAL, COLOR_RED_VISITED } from '../../utils/constants';

const useStyles = makeStyles({
  container: {
    marginTop: 80,
    marginBottom: 40,
    padding: '0px 80px 0px 160px',
    '@media (max-width:600px)': {
      padding: '0px 0px 20px 0px',
      marginTop: 40,
    },
  },
  titleAnalyst: {
    fontSize: '1.2em',
    fontWeight: 600,
    marginBottom: 8,
  },
  subTitleAnalyst: {
    margin: 0,
    fontSize: '0.8em',
  },
  analystContainer: {
    padding: '0px 80px 0px 160px',
    '@media (max-width:600px)': {
      padding: '20px 0px 20px 0px',
    },
  },
  links: {
    fontSize: '0.7em',
    color: COLOR_CORAL,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: COLOR_CORAL,
    },
    '&:visited': {
      color: COLOR_RED_VISITED,
    },
  },
});

export default () => {
  const classes = useStyles();
  return (
    // <>
    <Grid container className={classes.container}>
      <h3 style={{ marginBottom: 40 }}>Share performance</h3>
      <iframe
        title="vow"
        style={{ width: '100%', height: '1700px' }}
        src="https://ir.oms.no/component/standardPage?token=scanship_irn&lang=en"
        width="300"
        height="150"
        frameBorder="0"
      />
      <h3 style={{ marginBottom: 40, marginTop: 80 }}>Largest Shareholders</h3>
      <iframe
        title="vow"
        style={{ width: '100%', height: '500px', marginLeft: 0 }}
        src="https://ir.oms.no/component/shareholders?token=scanship_irn&lang=en"
        width="300"
        height="150"
        frameBorder="0"
      />
      <Grid item xs={12}>
        <h3 style={{ marginTop: 60 }}>EQUITY RESEARCH</h3>
      </Grid>
      <Grid item xs={12}>
        <p style={{ marginBottom: 20 }}>Analysts following VOW ASA</p>
      </Grid>
      <Grid container spacing={4}>
        <Grid item>
          <Grid item>
            <Grid container direction="column" justify="space-between">
              <Grid item>
                <h3 className={classes.titleAnalyst}>Sparebank 1 Markets</h3>
                <p className={classes.subTitleAnalyst}>Thomas Dowling Næss</p>
                <p className={classes.subTitleAnalyst}>
                  Email:{' '}
                  <a href="mailto:thomas.naess@sb1markets.no">
                    thomas.naess@sb1markets.no
                  </a>
                </p>
              </Grid>
              <Grid item>
                <h3 className={classes.titleAnalyst}>Pareto Securities</h3>
                <p className={classes.subTitleAnalyst}>Gard A. Aarvik</p>
                <p className={classes.subTitleAnalyst}>
                  Email:{' '}
                  <a href="mailto:gaard.aarvik@paretosec.com">
                    gaard.aarvik@paretosec.com
                  </a>
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid item>
            <Grid container direction="column" justify="space-between">
              <Grid item>
                <h3 className={classes.titleAnalyst}>DNB Markets</h3>
                <p className={classes.subTitleAnalyst}>TBC</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <h3 className={classes.titleAnalyst}>
              Clarksons Platou <br /> Securities AS
            </h3>
            <p className={classes.subTitleAnalyst}>Turner Holm</p>
            <p className={classes.subTitleAnalyst} style={{ marginTop: 4 }}>
              Email:{' '}
              <a href="mailto:turner.holm@clarksons.com">
                turner.holm@clarksons.com
              </a>
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
